import {STATE_OR_NAME, REVIEW_SAVED_CART, REVIEW_SAVED_CART_INSTANCES, REVIEW_STORE_ORDER_ADD, ADD_OFFERING_INSTANCES} from '../../../../../reducers/constants/add.offer.wizard.constants';

export function routing($stateProvider) {
    $stateProvider
        .state(STATE_OR_NAME, {
            url: '/createOffersOrder?offeringId?offeringInstanceId?action?storeOrderType',
            ignoreBack: true,
            params: {
                futureDatedOrderId: null,
                futureDatedOfferingId: null,
            },
            template: '<add-offer class="u-sizeFull u-flexKeepHeight"></add-offer>'
        }).state(REVIEW_SAVED_CART, {
            redirectTo: STATE_OR_NAME,
            ignoreBack: true,
        }).state(REVIEW_SAVED_CART_INSTANCES, {
            redirectTo: STATE_OR_NAME,
            ignoreBack: true,
        }).state(REVIEW_STORE_ORDER_ADD, {
            redirectTo: STATE_OR_NAME,
            ignoreBack: true,
        }).state(ADD_OFFERING_INSTANCES, {
            params: {
                futureDatedOrderId: null,
                futureDatedOfferingId: null,
            },
            ignoreBack: true,
        });
}
