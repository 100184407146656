import {
    SUBSCRIBER_TREATMENT_ACCESS
} from './../../../security.attributes';
import {CustomerCareTreatmentDetailsWindowTitleSelector} from '../../../reducers/selectors/customer.selectors';
export default function($stateProvider) {
    $stateProvider.state('index.customercare.customer.treatmentDetails', {
        url: '/treatmentDetails',
        data: {
            windowTitle: CustomerCareTreatmentDetailsWindowTitleSelector,
        },
        template: '<treatment-details></treatment-details>',
        securityAttributes: [SUBSCRIBER_TREATMENT_ACCESS]
    });
}