import PermissionTypes from 'invision-core/src/components/security/permission.types';

export const ACTIVE_SESSION_ACCESS = {
    id: 2016,
    type: PermissionTypes.BOOLEAN
};
export const AD_HOC_OVERRIDE = {
    id: 2021,
    type: PermissionTypes.BOOLEAN
};
export const ADDRESS_ACCESS = {
    id: 2004,
    type: PermissionTypes.SECURITY
};
export const ADDRESS_OVERRIDE_ACCESS = {
    id: 2022,
    type: PermissionTypes.SECURITY
};

export const ADJUSTMENT_LIMIT_AND_ACCESS = {
    id: 196,
    type: PermissionTypes.SECURITY
};
export const FINANCE_ADJUSTMENT_LIMIT_AND_ACCESS = {
    id: 208,
    type: PermissionTypes.SECURITY
};
export const ANNOUNCEMENT_ADMIN_ACCESS = {
    id: 8,
    type: PermissionTypes.SECURITY
};
export const SUBSCRIBER_ANONYMIZATION_ACCESS = {
    id: 134,
    type: PermissionTypes.BOOLEAN
};
export const ANONYMIZE_SUBSCRIBER_IMMEDIATELY_ACCESS = {
    id: 147,
    type: PermissionTypes.BOOLEAN
};
export const BATCH_PAYMENTS_ACCESS = {
    id: 2009,
    type: PermissionTypes.BOOLEAN
};
export const BILL_CYCLE_CHANGE_ACCESS = {
    id: 217,
    type: PermissionTypes.BOOLEAN
};
export const BULK_ENTITLEMENT_OVERRIDE_ACCESS = {
    id: 181,
    type: PermissionTypes.BOOLEAN
};
export const PRICE_OVERRIDE_ACCESS = {
    id: 180,
    type: PermissionTypes.BOOLEAN
};
export const BYPASS_PAYMENT_INSTRUMENT_REQUIRED = {
    id: 112,
    type: PermissionTypes.BOOLEAN
};
export const CANCEL_ORDER_ACCESS = {
    id: 77,
    type: PermissionTypes.BOOLEAN
};
export const CASE_ACCESS = {
    id: 198,
    type: PermissionTypes.SECURITY
};
export const CODE_TABLE_ADMINISTRATION_ACCESS = {
    id: 5,
    type: PermissionTypes.SECURITY
};
export const CONFIGURE_DEVICE_VERSION_SETTINGS = {
    id: 1711,
    type: PermissionTypes.BOOLEAN
};
export const CONVIVA_ACCESS = {
    id: 2015,
    type: PermissionTypes.BOOLEAN
};
export const CONVERGENT_BILLER_ACCESS = {
    id: 9200,
    type: PermissionTypes.BOOLEAN
};
export const CONVERGENT_BILLER_DEMO_ACCESS = {
    id: 9201,
    type: PermissionTypes.BOOLEAN
};
export const COUPON_ACCESS = {
    id: 2013,
    type: PermissionTypes.BOOLEAN
};
export const COUPON_GRANT = {
    id: 49,
    type: PermissionTypes.BOOLEAN
};
export const COUPON_REDEMPTION_CODE_UPDATE_ACCESS = {
    id: 2014,
    type: PermissionTypes.BOOLEAN
};
export const COUPON_REDEMPTION_CODE_VIEW_ACCESS = {
    id: 125,
    type: PermissionTypes.BOOLEAN
};
export const CREDIT_CLASS_VIEW_ACCESS = {
    id: 2032,
    type: PermissionTypes.SECURITY
};
export const DEVICE_ACCESS = {
    id: 2012,
    type: PermissionTypes.BOOLEAN
};
export const DEVICE_MANAGEMENT_ACCESS = {
    id: 98,
    type: PermissionTypes.SECURITY
};
export const DEVICE_PAIR_SMARTCARD_ACCESS = {
    id: 118,
    type: PermissionTypes.BOOLEAN
};
export const DISCOUNT_OVERRIDE_ACCESS = {
    id: 206,
    type: PermissionTypes.BOOLEAN
};
export const E_WALLET_ACCESS = {
    id: 2003,
    type: PermissionTypes.BOOLEAN
};
export const E_WALLET_ADMIN_ACCESS = {
    id: 12,
    type: PermissionTypes.SECURITY
};
export const EARLY_TERMINATION_FEE_OVERRIDE_ACCESS = {
    id: 192,
    type: PermissionTypes.BOOLEAN
};
export const ENTITLEMENT_ACCESS = {
    id: 80,
    type: PermissionTypes.SECURITY
};
export const EXTERNAL_BILL_ADMIN_ACCESS = {
    id: 141,
    type: PermissionTypes.BOOLEAN
};
export const GENERAL_LEDGER_ACCESS = {
    id: 3003,
    type: PermissionTypes.BOOLEAN
};
export const GIFT_CARD_ACCESS = {
    id: 2011,
    type: PermissionTypes.BOOLEAN
};
export const GRANT_ADDITIONAL_RIGHTS = {
    id: 88,
    type: PermissionTypes.BOOLEAN
};
export const GROUP_ADMIN_ACCESS = {
    id: 3,
    type: PermissionTypes.SECURITY
};
export const HOUSEHOLD_ACCESS = {
    id: 2002,
    type: PermissionTypes.BOOLEAN
};
export const HOUSEHOLD_ADMIN_ACCESS = {
    id: 11,
    type: PermissionTypes.SECURITY
};
export const INITIALIZE_DEVICE_ACCESS = {
    id: 122,
    type: PermissionTypes.BOOLEAN
};
export const ISSUE_CREDIT_BRANDABLE_CURRENCY_LIMIT = {
    id: 71,
    type: PermissionTypes.LIMIT
};
export const ISSUE_CREDIT_BRANDABLE_CURRENCY_LIMIT_PER_MONTH = {
    id: 73,
    type: PermissionTypes.LIMIT
};
export const ISSUE_CREDIT_STANDARD_CURRENCY_LIMIT = {
    id: 70,
    type: PermissionTypes.LIMIT
};
export const ISSUE_CREDIT_STANDARD_CURRENCY_LIMIT_PER_MONTH = {
    id: 72,
    type: PermissionTypes.LIMIT
};
export const ISSUE_CREDIT_TO_ALTERNATE_PAYMENT_INSTRUMENT = {
    id: 82,
    type: PermissionTypes.BOOLEAN
};
export const ISSUE_DISCRETIONARY_DISCOUNT = {
    id: 83,
    type: PermissionTypes.BOOLEAN
};
export const ISSUE_WRITE_OFF = {
    id: 74,
    type: PermissionTypes.BOOLEAN
};
export const MEMBER_REPORTS_ACCESS = {
    id: 4001,
    type: PermissionTypes.BOOLEAN
};

export const MULTIPLE_DISCOUNT_ACCESS = {
    id: 2030,
    type: PermissionTypes.BOOLEAN
};

export const ORDER_ACCESS = {
    id: 2005,
    type: PermissionTypes.BOOLEAN
};
export const ORDERING_ACCESS = {
    id: 29,
    type: PermissionTypes.SECURITY
};
export const OVERRIDE_BILLING_EFFECTIVE_DATE = {
    id: 2031,
    type: PermissionTypes.BOOLEAN
};
export const OVERRIDE_OFFER_QUALIFICATION_ENFORCEMENT = {
    id: 2019,
    type: PermissionTypes.BOOLEAN
};
export const PAYMENT_ACCESS = {
    id: 2008,
    type: PermissionTypes.BOOLEAN
};
export const PAYMENT_ACTIVITY_VIEW_ACCESS = {
    id: 117,
    type: PermissionTypes.BOOLEAN
};
export const PAYMENT_ADJUSTMENT_ACCESS = {
    id: 190,
    type: PermissionTypes.BOOLEAN
};
export const PAYMENT_INSTRUMENT_ADMIN_ACCESS = {
    id: 144,
    type: PermissionTypes.BOOLEAN
};
export const PAYMENT_INSTRUMENT_BLOCKLIST_ADMIN_ACCESS = {
    id: 130,
    type: PermissionTypes.SECURITY
};
export const PAYMENT_INSTRUMENT_ALLOWLIST_ADMIN_ACCESS = {
    id: 131,
    type: PermissionTypes.SECURITY
};
export const PLAYER_ADMIN_ACCESS = {
    id: 86,
    type: PermissionTypes.SECURITY
};
export const PORTAL_ACCESS = {
    id: 2000,
    type: PermissionTypes.BOOLEAN
};
export const PORTAL_ADMIN_ACCESS = {
    id: 6,
    type: PermissionTypes.SECURITY
};
export const PRODUCTS_ACCESS = {
    id: 2007,
    type: PermissionTypes.BOOLEAN
};
export const REFUND_LIMIT_AND_ACCESS = {
    id: 201,
    type: PermissionTypes.SECURITY
};
export const REMARK_ADMIN_ACCESS = {
    id: 14,
    type: PermissionTypes.SECURITY
};
export const REMARK_ATTACHMENT_SIZE_LIMIT = {
    id: 17,
    type: PermissionTypes.LIMIT
};
export const REMARK_EXTERNAL_ADMIN_ACCESS = {
    id: 15,
    type: PermissionTypes.SECURITY
};
export const REMARK_OVERWRITE_ADMIN_ACCESS = {
    id: 16,
    type: PermissionTypes.SECURITY
};
export const REMARK_UPDATE_AS_READ = {
    id: 18,
    type: PermissionTypes.BOOLEAN
};
export const RENEW_ORDER_WITHOUT_CHARGE_ACCESS = {
    id: 31,
    type: PermissionTypes.BOOLEAN
};
export const RESEND_GIFT_CARD_ACCESS = {
    id: 19,
    type: PermissionTypes.BOOLEAN
};
export const RESET_SUBSCRIBER_PASSWORD_ACCESS = {
    id: 37,
    type: PermissionTypes.BOOLEAN
};
export const RESET_USER_PASSWORD_ACCESS = {
    id: 119,
    type: PermissionTypes.BOOLEAN
};
export const RESUBMIT_ORDER_ACCESS = {
    id: 78,
    type: PermissionTypes.BOOLEAN
};
export const RETURN_DEVICE_ACCESS = {
    id: 205,
    type: PermissionTypes.BOOLEAN
};
export const ROLE_ADMIN_ACCESS = {
    id: 2,
    type: PermissionTypes.SECURITY
};
export const SEND_EMAIL = {
    id: 39,
    type: PermissionTypes.BOOLEAN
};
export const SEND_GIFT_ORDER_ACCESS = {
    id: 139,
    type: PermissionTypes.BOOLEAN
};
export const SEND_TRANSACTION_RECEIPT_ACCESS = {
    id: 138,
    type: PermissionTypes.BOOLEAN
};
export const SPECIFY_EXTERNAL_SKU = {
    id: 65,
    type: PermissionTypes.SECURITY
};
export const SUBSCRIBER_ACCESS = {
    id: 2001,
    type: PermissionTypes.SECURITY
};
export const SUBSCRIBER_ADMIN_ACCESS = {
    id: 10,
    type: PermissionTypes.SECURITY
};
export const SUBSCRIBER_NETOWRK_SUSPENSION = {
    id: 2026,
    type: PermissionTypes.BOOLEAN
};
export const SUBSCRIBER_BILLING_AND_NETOWRK_SUSPENSION = {
    id: 2025,
    type: PermissionTypes.BOOLEAN
};
export const SUBSCRIBER_FINANCE_INSTALLMENT_SUSPEND_AND_RESUME_ACCESS = {
    id: 2027,
    type: PermissionTypes.BOOLEAN
};
export const SUBSCRIBER_BYPASS_RESET_PASSWORD = {
    id: 194,
    type: PermissionTypes.BOOLEAN
};
export const SUBSCRIBER_CREATE = {
    id: 136,
    type: PermissionTypes.BOOLEAN
};
export const SUBSCRIBER_FIND = {
    id: 13,
    type: PermissionTypes.BOOLEAN
};
export const SUBSCRIBER_PASSWORD_CHALLENGE_RESPONSE_ACCESS = {
    id: 111,
    type: PermissionTypes.BOOLEAN
};
export const SUBSCRIBER_PRODUCT_REMOVE_DISCOUNT_ACCESS = {
    id: 113,
    type: PermissionTypes.BOOLEAN
};
export const SUBSCRIBER_REMOVE = {
    id: 135,
    type: PermissionTypes.BOOLEAN
};
export const SUBSCRIBER_TYPE_ACCESS = {
    id: 104,
    type: PermissionTypes.BOOLEAN
};
export const SUBSCRIBER_TYPE_EDIT_ACCESS = {
    id: 2024,
    type: PermissionTypes.BOOLEAN
};
export const SUBSCRIPTION_ACCESS = {
    id: 2006,
    type: PermissionTypes.SECURITY
};
export const SUBSCRIPTION_REMOVE_ADJUSTMENT = {
    id: 63,
    type: PermissionTypes.BOOLEAN
};
export const SUBSCRIPTION_TERMINATION_FEE_WAIVER = {
    id: 38,
    type: PermissionTypes.BOOLEAN
};
export const TICKET_ACCESS = {
    id: 2010,
    type: PermissionTypes.BOOLEAN
};
export const TRANSFER_SERVICES = {
    id: 211,
    type: PermissionTypes.SECURITY
};
export const TROUBLE_MANAGEMENT_ADMIN_ACCESS = {
    id: 96,
    type: PermissionTypes.SECURITY
};
export const UPDATE_PIN_ACCESS = {
    id: 215,
    type: PermissionTypes.SECURITY
};
export const UPDATE_SERVICE_LIFECYCLE_STATUS = {
    id: 227,
    type: PermissionTypes.SECURITY
};
export const USER_ROLE_AND_GROUP_ASSIGNMENT_ACCESS = {
    id: 137,
    type: PermissionTypes.SECURITY
};
export const VIEW_FUTURE_INVOICES_ACCESS = {
    id: 2033,
    type: PermissionTypes.BOOLEAN
};
export const VIEW_INVOICE_DROPDOWN_ACCESS = {
    id: 218,
    type: PermissionTypes.BOOLEAN
};
export const WORKFLOW_CANCEL_ACCESS = {
    id: 24,
    type: PermissionTypes.SECURITY
};
export const WORKFLOW_CHANGE_EXECUTION_ACCESS = {
    id: 25,
    type: PermissionTypes.SECURITY
};
export const WORKFLOW_COMPLETE_ACTIVITY_MANUALLY_ACCESS = {
    id: 22,
    type: PermissionTypes.SECURITY
};
export const WORKFLOW_EXECUTION_ACCESS = {
    id: 20,
    type: PermissionTypes.SECURITY
};
export const WORKFLOW_EXECUTION_ACTIVITY_ASSIGNMENT_ACCESS = {
    id: 27,
    type: PermissionTypes.SECURITY
};
export const WORKFLOW_EXECUTION_HISTORY_ACCESS = {
    id: 26,
    type: PermissionTypes.SECURITY
};
export const WORKFLOW_EXECUTION_PRIORITY = {
    id: 28,
    type: PermissionTypes.SECURITY
};
export const WORKFLOW_RESTART_ACCESS = {
    id: 23,
    type: PermissionTypes.SECURITY
};
export const ENTITLEMENT_BALANCE_UPDATE_ACCESS = {
    id: 2035,
    type: PermissionTypes.BOOLEAN
};
export const ALLOW_CREDIT_DEBIT_NOTE = {
    id: 224,
    type: PermissionTypes.BOOLEAN
};
export const ONE_TIME_MAKE_PAYMENT = {
    id: 2034,
    type: PermissionTypes.BOOLEAN
};
export const DISPUTE_INVOICE = {
    id: 198,
    type: PermissionTypes.BOOLEAN
};
export const RATE_SHEET_EDIT_ACCESS = {
    id: 2036,
    type: PermissionTypes.BOOLEAN
};
export const POLICY_COUNTERS_EDIT_ACCESS = {
    id: 2037,
    type: PermissionTypes.BOOLEAN
};
export const FEES_EDIT_ACCESS = {
    id: 2038,
    type: PermissionTypes.SECURITY
};
export const NOTIFICATIONS_ACCESS = {
    id: 303,
    type: PermissionTypes.BOOLEAN
};
export const PII_ACCESS = {
    id: 406,
    type: PermissionTypes.BOOLEAN
};
export const SUBSCRIBER_TREATMENT_ACCESS = {
    id: 2045,
    type: PermissionTypes.SECURITY
};
export const SUBSCRIBER_HIERARCHY_ACCESS = {
    id: 2046,
    type: PermissionTypes.SECURITY
};