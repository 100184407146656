import {
    EDIT_OFFERING_INSTANCES,
    STATE_OR_NAME,
    RECONNECT_OFFER_STATE_NAME,
    REVIEW_STORE_ORDER_EDIT
} from '../../../../../reducers/constants/edit.offer.wizard.constants';

export function routing($stateProvider) {
    $stateProvider
        .state(STATE_OR_NAME, {
            url: '/offering/:offeringId/offeringInstance/:offeringInstanceId/edit?contractId&contractInstanceId',
            ignoreBack: true,
            params: {
                contractId: null,
                contractInstanceId: null,
                futureDatedOrderId: null
            },
            template: '<edit-offer class="u-sizeFull u-flexKeepHeight"></edit-offer>'
        })
        .state(RECONNECT_OFFER_STATE_NAME, {
            url: '/offering/:offeringId/offeringInstance/:offeringInstanceId/reconnect?contractId&contractInstanceId',
            ignoreBack: true,
            params: {
                contractId: null,
                contractInstanceId: null,
                reconnectOffer: true
            },
            template: '<edit-offer class="u-sizeFull u-flexKeepHeight"></edit-offer>'
        }).state(REVIEW_STORE_ORDER_EDIT, {
            url: '/offering/:offeringId/offeringInstance/:offeringInstanceId/edit?contractId&contractInstanceId',
            ignoreBack: true,
            params: {
                contractId: null,
                contractInstanceId: null
            },
            template: '<edit-offer class="u-sizeFull u-flexKeepHeight"></edit-offer>'
        }).state(EDIT_OFFERING_INSTANCES, {
            url: '/offering/:offeringId/offeringInstance/:offeringInstanceId/edit',
            ignoreBack: true,
            params: {
                contractId: null,
                contractInstanceId: null,
                futureDatedOrderId: null
            }
        });
}
