import {stateGo} from 'redux-ui-router';
import CustomerLocaleKeys from '../../../locales/keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {fetchProductLineOfBusinessDeliveryCapability} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {PageSizePreferenceSelector} from 'invision-core/src/components/session/session.selectors';

import {CurrentCustomerSelector} from 'invision-core/src/components/customer/customer.selectors';
import {NOTIFICATION_TIME_LENGTH} from '../../../customercare.constants';
import * as ContentActivitySelectors from '../../../reducers/selectors/customer.content.activity.selectors';
import {CurrentCustomerIdSelector, RouteParams} from '../../../reducers/selectors/customer.selectors';
import * as ContentActivityActions from '../../../reducers/actions/customer.content.activity.actions';
import {getProductMetadata} from '../../../reducers/actions/products.actions';
import {DETAIL_STATE_OR_NAME as PRODUCT_DETAILS_PAGE_REDIRECT} from '../products/products.config';

const REMOVABLE = false;
const PAGINATION_SLOTS = 0;

class ContentActivityController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            CustomerLocaleKeys,
            uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                activeIsSelected: ContentActivitySelectors.ActiveIsSelectedSelector(store),
                activeItemCount: ContentActivitySelectors.ActiveItemCountSelector(store),
                allContentActivity: ContentActivitySelectors.AllContentActivityCollectionSelector(store),
                backBannerText: ContentActivitySelectors.BackBannerTextSelector(store),
                customerContentActivity: ContentActivitySelectors.CustomerContentActivityCollectionSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentListHasContentActivity: ContentActivitySelectors.CurrentListHasContentActivitySelector(store),
                currentPage: ContentActivitySelectors.CurrentPageSelector(store),
                deviceFilterText: ContentActivitySelectors.DeviceFilterTextSelector(store),
                deviceId: ContentActivitySelectors.DeviceIdRouteParamSelector(store),
                deviceName: ContentActivitySelectors.DeviceNameRouteParamSelector(store),
                emptyStateGlyph: ContentActivitySelectors.EmptyStateGlyphSelector(store),
                emptyStateMessage: ContentActivitySelectors.EmptyStateMessageSelector(store),
                errorMessage: ContentActivitySelectors.ContentActivityErrorSelector(store),
                hasActiveContentActivity: ContentActivitySelectors.HasActiveContentActivitySelector(store),
                hasAnyContentActivity: ContentActivitySelectors.HasAnyContentActivitySelector(store),
                hasFilteredContentActivity: ContentActivitySelectors.HasFilteredContentActivitySelector(store),
                hasLoadedContentActivity: ContentActivitySelectors.HasLoadedContentActivitySelector(store),
                isFetchingContentActivity: ContentActivitySelectors.IsFetchingContentActivitySelector(store),
                isLoadingContentActivity: ContentActivitySelectors.IsLoadingContentActivitySelector(store),
                isLoadingDetails: ContentActivitySelectors.IsLoadingDetails(store),
                isLoadingProduct: ContentActivitySelectors.IsLoadingProductSelector(store),
                isPreviousProductsRoute: ContentActivitySelectors.IsPreviousProductsRouteSelector(store),
                previousRoute: ContentActivitySelectors.PreviousRouteSelector(store),
                productId: ContentActivitySelectors.ProductIdRouteParamSelector(store),
                productLOBDeliveryCapabilityLoaded: MetadataCodeLoadedSelector(CODES.ProductLineOfBusinessDeliveryCapability, store),
                productFilterText: ContentActivitySelectors.ProductFilterTextSelector(store),
                productName: ContentActivitySelectors.ProductNameSelector(store),
                recordCount: ContentActivitySelectors.RecordCountSelector(store),
                routeParams: RouteParams(store),
                selectedActivity: ContentActivitySelectors.SelectedActivitySelector(store),
                selectedPageSizePreference: ContentActivitySelectors.SelectedPageSizePreference(store),
                selectedProduct: ContentActivitySelectors.SelectedProductSelector(store),
                shouldShowBackBanner: ContentActivitySelectors.ShouldShowBackBannerSelector(store),
                showListEmptyState: ContentActivitySelectors.ShowListEmptyStateSelector(store),
                showBackBanner: ContentActivitySelectors.ShowRouteBackBannerSelector(store),
                userPageSizePreference: PageSizePreferenceSelector(store),
            };
        };

        const controllerActions = {
            cancelContentActivity: ContentActivityActions.cancelContentActivity,
            fetchCustomerContentActivity: ContentActivityActions.fetchCustomerContentActivity,
            fetchProductLineOfBusinessDeliveryCapability,
            getProductMetadata,
            setActiveIsSelected: ContentActivityActions.setActiveIsSelected,
            setCurrentPage: ContentActivityActions.setCurrentPage,
            setPageSizePreference: ContentActivityActions.setPageSizePreference,
            setSelectedContentActivity: ContentActivityActions.setSelectedContentActivity,
            showBackBanner: ContentActivityActions.showBackBanner,
            stateGo
        };

        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.productLOBDeliveryCapabilityLoaded) {
            this.actions.fetchProductLineOfBusinessDeliveryCapability();
        }

        if (!this.state.hasLoadedContentActivity) {
            this.retrieveCustomerContentActivity()
                .then(() => {
                    this.setSelectedTabAndSetNotificationCount();
                });
        }

        if (!this.state.productId && !this.state.deviceId) {
            this.actions.stateGo('index.customercare.customer.contentActivity', null, {
                location: 'replace'
            });
        } else {
            this.actions.showBackBanner(true);
            if (this.state.productId && this.state.routeParams && this.state.routeParams.lockerItemId) {
                this.ShowBackBanner= true;
                this.previousRoute= {
                    name: PRODUCT_DETAILS_PAGE_REDIRECT,
                    params: {
                        lockerItemId: this.state.routeParams.lockerItemId
                    }
                };
            }

        }

        this.stateOrName = this.state.productId || this.state.deviceId ? 'index.customercare.customer.contentActivity.product' : 'index.customercare.customer.contentActivity';
        this.optionalParams = {
            customerId: this.state.currentCustomerId,
            productId: this.state.productId,
            deviceId: this.state.deviceId
        };

        this.activityTabs = [{
            id: 0,
            glyph: ContentActivitySelectors.GLYPH.CLOCK_O,
            active: this.state.activeIsSelected,
            hasEmphasis: true,
            notificationCount : null,
            showActive: true
        }, {
            id: 1,
            glyph: ContentActivitySelectors.GLYPH.ARCHIVE,
            active: this.state.activeIsSelected,
            notificationCount : null,
            showActive: false
        }];

        this.removable = REMOVABLE;
        this.maximumPaginationSlots = PAGINATION_SLOTS;
    }

    $onDestroy() {
        this.disconnect();
    }

    cancelStream(contentActivity) {
        const params = {
            Reference: contentActivity.Reference,
            PricingPlanId: contentActivity.PricingPlanId
        };

        this.actions.cancelContentActivity(this.state.currentCustomerId, params)
            .then(() => {
                const localeKey = contentActivity.Download ?
                    CustomerLocaleKeys.CONTENT_ACTIVITY.CANCEL_DOWNLOAD.TERMINATED :
                    CustomerLocaleKeys.CONTENT_ACTIVITY.CANCEL_STREAM.TERMINATED;

                return Promise.all([
                    this.retrieveCustomerContentActivity(),
                    this.uiNotificationService.success(i18n.translate(localeKey), null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    })
                ]);
            })
            .then(() => {
                if (this.state.activeItemCount > 0) {
                    this.actions.setSelectedContentActivity('1');
                }
            })
            .catch(() => {
                this.uiNotificationService.error(this.state.errorMessage, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
    }

    setSelectedTabAndSetNotificationCount() {
        this.actions.setActiveIsSelected(this.state.hasActiveContentActivity);

        this.setActiveTabAndNotificationCount();
    }

    setActiveTabAndNotificationCount() {
        this.activityTabs.forEach((tab) => {
            tab.active = this.state.activeIsSelected === tab.showActive;
            if (tab.showActive) {
                tab.notificationCount = this.state.activeItemCount;
            }
        });
    }

    handleContentActivitySelected(contentActivity) {
        this.actions.getProductMetadata(contentActivity.ProductId, this.state.currentCustomer.Language);
        this.actions.setSelectedContentActivity(contentActivity.Id);
    }

    retrieveCustomerContentActivity() {
        if (!this.state.selectedPageSizePreference) {
            this.actions.setPageSizePreference(this.state.userPageSizePreference);
        }

        const additionalParams = {
            PhysicalDeviceId: this.state.deviceId || undefined,
            ProductId: (this.state.productId) ? this.state.productId : undefined,
            PageNumber: this.state.currentPage,
            PageSize: this.state.selectedPageSizePreference
        };

        return this.actions.fetchCustomerContentActivity(this.state.currentCustomerId, additionalParams).then(() => {
            if (this.state.selectedActivity.ProductId) {
                this.actions.getProductMetadata(this.state.selectedActivity.ProductId, this.state.currentCustomer.Language);
            }
            this.setSelectedTabAndSetNotificationCount();
        });
    }

    removeRouteFilter() {
        this.actions.stateGo('index.customercare.customer.contentActivity', null, {
            reload: true
        });
    }

    setCurrentPage(page) {
        this.actions.setCurrentPage(page);
        this.retrieveCustomerContentActivity();
    }

    onBannerClose() {
        this.actions.showBackBanner(false);
    }

    handleTabSelected(tabSelected) {
        this.activityTabs.forEach((tab) => {
            tab.active = tab.id === tabSelected.id;
        });

        this.actions.setActiveIsSelected(tabSelected.showActive);
        this.actions.setSelectedContentActivity(this.state.customerContentActivity.length ? this.state.customerContentActivity[0].Id : null);
    }

    onPageSizeOptionSelected(pageSize) {
        this.actions.setPageSizePreference(pageSize);
        this.setCurrentPage(1);
    }
}

export default {
    controller: ContentActivityController,
    controllerAs: 'ContentActivityController',
    template: require('./content.activity.html')
};
